import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
  }

  updateMetaInfo(Content: any, author: any, category: any) {
    this.meta.updateTag({ name: 'description', content: Content });
    this.meta.updateTag({ name: 'author', content: author });
    this.meta.updateTag({ name: 'keywords', content: category });
  }

  updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)).subscribe((event) => {
            this.titleService.setTitle(event['title'] + ' - Amerikadaniste');
          });
    } else {
      if (title == 'Amerikadaniste') {
        this.titleService.setTitle(title + ' - ' + this.translateService.instant('META_US_ADDRESS'));
      } else {
        this.titleService.setTitle(title + ' - Amerikadaniste');
      }
    }
  }
}
